import { mealsOptions } from "./service/views/PortionsRegistry/inputs";
import { FormInput, FormSection, SectionProperties } from "./types";

export const logoutUser = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
  window.location.href = `https://${import.meta.env.VITE_USER_POOL_DOMAIN}/logout?client_id=${import.meta.env.VITE_USER_POOL_ID}&logout_uri=${getRedirectURi()}`;
}

export const getRedirectURi = () => {
  const uri = import.meta.env.MODE === 'development' ? 'http://localhost:5173' : `https://${import.meta.env.VITE_BO_DOMAIN}`;
  return encodeURIComponent(uri);
}

export const floatKeysToObject = (keys: Array<string>, values: any) =>
  keys
    .filter((val) => values[val] !== '')
    .reduce((res, val) => ({ ...res, [val]: parseFloat(values[val]) }), {})

export const sumComponentsProperty = (foods: any, components: any, property: string, portionSize: number) => components?.reduce((res: any, data: any) => {
  const food = foods.find(({ fid }: any) => fid === data.key.value);
  if (!food || data.value === '') return 0;
  return ((res + (food[property] ?? 0)) * parseFloat(data.value)) / portionSize;
}, 0);

export const ingredientsToString = (ingredients: any, foods: any) => {
  if (!ingredients) {
    return '';
  }

  return Object.entries(ingredients)
    .map(([key, value]) => ([foods.find(({ fid }: any) => fid === key)?.identification?.name_specific, value]))
    .map((values) => values.join(': '))
    .join(', ');
}

export const mealsToString = (meals: any) => {
  return mealsOptions.filter(({ value }: any) => meals.includes(parseInt(value, 10))).map(({ label }) => label).join(', ');
}

export const sizesToString = (sizes: any) => {
  return JSON.stringify(sizes)
}

export const measuresToString = (measurements: any) => {
  if (!measurements) {
    return '';
  }

  return `Porción típica (${measurements.unit}): ${measurements.portion} - Tamaños (${measurements.unit}): ${measurements.sizes.join(', ')}`.replace(/,/g, ', ');
}

export const getFoodNameFromId = (id: string, options: any) => {
  return options.find(({ value }: any) => value === id)?.label;
}

/**
 * Retrieves the form section data based on the provided values and array.
 * @param values - The values representing the form section or section properties.
 * @param arr - The array containing key-value pairs of strings and form sections or section properties.
 * @returns An object containing the section grid, section gap, section type, title, and content.
 */
export const getFormSectionData = (values: FormSection | SectionProperties, arr: [string, FormSection | SectionProperties][]) => {
  if ('sectionProperties' in values) {
    const { sectionProperties, ...content } = values;
    const { sectionGrid, sectionGap, sectionType, title } = sectionProperties;

    return { sectionGrid, sectionGap, sectionType, title, content }
  }

  const { sectionGrid, sectionGap, sectionType, title } = values;
  const content = Object.fromEntries(arr.filter(([, subValues]: [string, FormSection | SectionProperties]) => 'label' in subValues) as Iterable<readonly [string, FormSection | SectionProperties]>);

  return { sectionGrid, sectionGap, sectionType, title, content }
}


/**
 * Returns a subset of properties from the given item based on the provided inputs array.
 * @param inputsArray - An array of input names and their corresponding section properties.
 * @param item - The item from which to extract the properties.
 * @returns A new object containing the subset of properties from the item.
 */
export const getBaseInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => {
  const items = inputsArray.filter(([, values]) => 'label' in values);

  if (items.length === 0) {
    return undefined;
  }

  return items.reduce((res, [key]) => ({ ...res, [key]: item[key] }), {});
}

/**
 * Filters an array of inputs based on the section type.
 * @param inputsArray - The array of inputs to filter.
 * @param sectionType - The section type to filter by.
 * @returns An array of inputs that match the specified section type.
 */
export const filterSectionTypes = (inputsArray: [string, FormSection | SectionProperties][], sectionType: string) => inputsArray.filter(([, values]) => 'sectionProperties' in values)
  .filter(
    ([, { sectionProperties }]: [string, FormInput]) =>
      sectionProperties.sectionType === sectionType
  )

/**
* Parses the inputs array and returns an object containing the values of the normal inputs.
* 
* @param inputsArray - An array of input values along with their corresponding section properties.
* @param item - The item object containing the input values.
* @returns An object containing the values of the normal inputs.
*/
export const parseNormalInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => {
  return filterSectionTypes(inputsArray, 'normal')
    .reduce((res: any, [key, values]: [string, FormSection | SectionProperties]) => {
      const baseValues = Object.entries(values)
        .filter(([subKey]: any) => subKey !== 'sectionProperties')
        .filter(([_, subValue]: any) => subValue.component === undefined)
        .reduce(
          (subRes, [subKey]) => ({
            ...subRes,
            [subKey]: item[key]?.[subKey],
          }),
          {}
        );

      return { ...res, ...baseValues };
    }, {});
}

/**
 * Parses the select inputs from an inputs array and returns the selected options.
 * @param inputsArray - An array of inputs containing their names and properties.
 * @param item - The item object containing the selected values.
 * @returns An object containing the selected options for each input.
 */
export const parseSelectInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => {
  return filterSectionTypes(inputsArray, 'normal')
    .reduce((res: any, [_, values]: any) => {
      const selecteds = Object.entries(values)
        .filter(
          ([_, subValues]: any) =>
            subValues.component === 'multiselect' ||
            subValues.component === 'select'
        )
        .reduce(
          (subRes: any, [subKey, { options }]: any) => ({
            ...subRes,
            [subKey]: options.filter(
              ({ value: subValue }: any) => item[subKey]?.[subValue]
            ),
          }),
          undefined
        );
      return { ...res, ...selecteds };
    }, undefined);
}


/**
 * Parses the ingredients form based on the provided inputs array and item.
 * 
 * @param inputsArray - An array of inputs for the form.
 * @param item - The item containing the ingredients data.
 * @returns The parsed ingredients form.
 */
export const parseIngredientsFormInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => filterSectionTypes(inputsArray, 'ingredientsForm').reduce(
  (res: any, [key]: any) => ({
    ...res,
    [key]: Object.entries(item[key]).map(([subKey, subValue]) => ({
      key: { label: subKey, value: subKey },
      value: subValue,
    })),
  }),
  undefined
);

/**
 * Parses the meals form inputs array and filters it based on the section type 'mealsForm'.
 * Maps the values of the 'item' object based on the keys in the inputs array.
 * Each value is mapped to an array of meal options based on the meal number.
 * 
 * @param inputsArray - The array of inputs and their corresponding section properties.
 * @param item - The object containing the meal values.
 * @returns The parsed meals form object.
 */
export const parseMealsFormInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => filterSectionTypes(inputsArray, 'mealsForm').reduce(
  (res: any, [key]: any) => ({
    ...res,
    [key]: item[key].map((mealNumber: number) =>
      mealsOptions.find(
        ({ value: subValue }) => mealNumber.toString() === subValue
      )
    ),
  }),
  undefined
);

/**
 * Parses the sizes form based on the given inputs array and item.
 * 
 * @param inputsArray - An array of inputs and their corresponding form sections or section properties.
 * @param item - The item containing the sizes form data.
 * @returns The parsed sizes form.
 */
export const parseSizesFormInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => filterSectionTypes(inputsArray, 'sizesForm').reduce(
  (res: any, [key]: any) => ({
    ...res,
    [key]: Object.entries(item[key]),
  }),
  undefined
);

/**
 * Parses the "others" form section from an inputs array and returns the corresponding values from the given item.
 * 
 * @param inputsArray - An array of inputs and their corresponding form sections or section properties.
 * @param item - The item from which to extract the values.
 * @returns An object containing the parsed values from the "others" form section.
 */
export const parseOthersFormInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => filterSectionTypes(inputsArray, 'others').reduce(
  (res: any, [key]: any) => ({
    ...res,
    [key]: item[key]
      ? Object.entries(item[key]).map(([subKey, subValues]: any) => ({
        key: subKey,
        name: subValues.name,
        value: subValues.value,
      }))
      : undefined,
  }),
  undefined
);

/**
 * Parses the base inputs DTO by converting the specified key's value to a number if the type is 'number',
 * or keeps it as is otherwise.
 * 
 * @param res - The base inputs DTO object to be updated.
 * @param raw - The raw input object containing the values to be parsed.
 * @param key - The key of the value to be parsed.
 * @param values - The metadata object containing the type information.
 * @returns The updated base inputs DTO object.
 */
export const parseBaseInputsDTO = (res: any, raw: any, key: string, values: any) => ({
  ...res,
  [key]: values.type === 'number' ? parseFloat(raw[key]) : raw[key],
})

/**
 * Parses the normal inputs DTO and returns the result.
 * @param res - The result object.
 * @param raw - The raw input object.
 * @param key - The key for the subContent object.
 * @param values - The values object.
 * @returns The parsed normal inputs DTO.
 */
export const parseNormalInputsDTO = (res: any, raw: any, key: string, values: any) => {
  console.log("parsernormal", values)
  const subContent = Object.entries(values)
    .filter(([subKey]) => subKey !== 'sectionProperties')
    .filter(([subKey]) => raw[subKey] !== '')
    .reduce<any>((subRes, [subKey, subValues]) => {
      console.log("sbuvalues", subKey, subValues);
      const { type, component } = subValues as any;

      if (raw[subKey] === undefined) {
        return subRes;
      }

      if (component === 'multiselect') {
        return raw[subKey].reduce((subRes2: any, subValues2: any) => {
          return { ...subRes2, [subValues2.value]: true };
        }, {});
      }

      return {
        ...subRes,
        [subKey]:
          type === 'number' ? parseFloat(raw[subKey]) : raw[subKey],
      };
    }, {});

  if (
    Object.keys(subContent).length > 0 &&
    subContent !== undefined
  ) {
    return {
      ...res,
      [key]: subContent,
    };
  }

  return res;
}

export const parseMeasurementsFormInputs = (inputsArray: [string, FormSection | SectionProperties][], item: Record<string, any>) => {
  const inputs = filterSectionTypes(inputsArray, 'measuresForm');
  if (inputs.length === 0) {
    return undefined;
  }

  console.log("parseMeasurementsFormInputs", inputs, item)
  const { measurements } = item;

  if (!measurements) {
    return undefined;
  }

  return {
    measurements_portion: measurements.portion,
    measurements_unit: measurements.unit,
    measurements: measurements.sizes,
  }
}

/**
 * Parses the ingredients form DTO and returns the result.
 * 
 * @param res - The result object.
 * @param raw - The raw data object.
 * @param key - The key to access the data in the raw object.
 * @returns The parsed result object.
 */
export const parseIngredientsFormDTO = (res: any, raw: any, key: string) => {
  if (raw[key] === undefined || raw[key].length === 0) {
    return res;
  }

  const subContent = raw[key].reduce(
    (subRes: any, subValues: any) => {
      return {
        ...subRes,
        [subValues.key.value]: parseFloat(subValues.value),
      };
    },
    {}
  );

  return {
    ...res,
    [key]: subContent,
  };
}

/**
 * Parses the meals form DTO by extracting the specified key from the raw object
 * and converting its values to an array of integers.
 * 
 * @param res - The result object to be updated with the parsed content.
 * @param raw - The raw object containing the form data.
 * @param key - The key to be extracted from the raw object.
 * @returns The updated result object with the parsed content.
 */
export const parseMealsFormDTO = (res: any, raw: any, key: string) => {
  if (raw[key] === undefined) {
    return res;
  }
  const subContent = raw[key].reduce(
    (subRes: any, subValues: any) => {
      return [...subRes, parseInt(subValues.value, 10)];
    },
    []
  );

  return {
    ...res,
    [key]: subContent,
  };
}

export const parseSizesFormDTO = (res: any, raw: any, key: string) => {
  const subContent = raw[key].reduce(
    (subRes: any, subValues: any) => {
      return {
        ...subRes,
        [parseInt(subValues.key, 10)]: {
          name: subValues.name,
          prepared: parseFloat(subValues.prepared),
          reference: subValues.reference,
        },
      };
    },
    {}
  );

  return {
    ...res,
    [key]: subContent,
  };
}

/**
 * Returns the same object as the input. Resume form is for the portions view only
*/
export const parseResumeFormDTO = (res: any) => res

export const parseOthersFormDTO = (res: any, raw: any, key: string) => {
  const subContent = raw[key].reduce(
    (subRes: any, subValues: any) => {
      return {
        ...subRes,
        [subValues.key]: {
          name: subValues.name,
          value: subValues.value,
        },
      };
    },
    {}
  );

  return {
    ...res,
    [key]: subContent,
  };
}

export const parseMeasuresFormDTO = (res: any, raw: any) => {
  console.log(raw);
  const response = {
    measurements: {
      portion: parseInt(raw.measurements_portion, 10),
      unit: raw.measurements_unit.value,
      sizes: raw.measurements.map((size: string) => parseInt(size, 10))
    }
  }

  return { ...res, ...response }
}

