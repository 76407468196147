import { FormInputContent } from '../../../types';
export const inputs: FormInputContent = {
  identification: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Identificación',
    },
    name_generic: {
      label: 'Nombre genérico',
      required: true,
    },
    name_differentiator: {
      label: 'Nombre diferenciador',
    },
  },
  ingredients: {
    sectionProperties: {
      sectionType: 'ingredientsForm',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Ingredientes',
    },
  },
  meals: {
    sectionProperties: {
      sectionType: 'mealsForm',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Comidas',
    },
  },
  sizes: {
    sectionProperties: {
      sectionType: 'sizesForm',
      sectionGrid: 4,
      sectionGap: 4,
      title: 'Tamaños',
    },
  },
  resume: {
    sectionProperties: {
      sectionType: 'resume',
      sectionGrid: 4,
      sectionGap: 4,
      title: 'Resumen',
    },
  },
};

export const mealsOptions = [
  { label: 'Suplemento', value: '0' },
  { label: 'Desayuno', value: '1' },
  { label: 'Primera colación de mañana', value: '2' },
  { label: 'Segunda colación de mañana', value: '3' },
  { label: 'Almuerzo', value: '4' },
  { label: 'Primera colación de tarde', value: '5' },
  { label: 'Segunda colación de tarde', value: '6' },
  { label: 'Cena', value: '7' },
  { label: 'Primera colación de noche', value: '8' },
  { label: 'Segunda colación de noche', value: '9' },
];
